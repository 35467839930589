const ruPlural = {
    bonuses: ["бонус", "бонуса", "бонусов"],
    checkList: ["чек-лист", "чек-листа", "чек-листов"],
    clients: ["гость", "гостя", "гостей"],
    clientCards: ["карточка", "карточки", "карточек"],
    complaints: ["обращение", "обращения", "обращений"],
    examinations: ["аттестация", "аттестации", "аттестаций"],
    employees: ["сотрудник", "сотрудника", "сотрудников"],
    errors: ["ошибка", "ошибки", "ошибок"],
    feedback: ["отзыв", "отзыва", "отзывов"],
    goods: ["товар", "товара", "товаров"],
    grades: ["грейд", "грейда", "грейдов"],
    journals: ["журнал", "журнала", "журналов"],
    lessons: ["урок", "урока", "уроков"],
    offences: ["нарушение", "нарушения", "нарушений"],
    questions: ["вопрос", "вопроса", "вопросов"],
    numbers: ["номер", "номера", "номеров"],
    rubs: ["рубль", "рубля", "рублей"],
    receipts: ["чек", "чека", "чеков"],
    scores: ["балл", "балла", "баллов"],
    steps: ["шаг", "шага", "шагов"],
    templates: ["шаблон", "шаблона", "шаблонов"],
    unreadNews: ["непрочитанная новость", "непрочитанные новости", "непрочитанных новостей"],
};

export default ruPlural;
